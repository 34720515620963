export type EnvType = 'local' | 'uat' | 'production';
export const isEnv = (env: EnvType): boolean => {
  if (env === 'local') {
    return window.location.host.includes('localhost');
  }

  if (env === 'uat') {
    return window.location.host.includes('uat');
  }

  if (env === 'production') {
    return window.location.host.includes('certifi.dob.dc.gov');
  }

  return false;
};
