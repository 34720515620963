import { Box, Divider, SxProps, Theme, useTheme } from '@mui/material';

export interface StatusProps {
  data: InspectionStatusData | null;
}

export interface InspectionStatusData {
  state: InspectionState;
  date: string;
}

export type InspectionState =
  | "pending"
  | "scheduled"
  | "disapproved"
  | "approved"
  | "rescheduled";

const getColor = (id: InspectionState, theme: Theme) => {
  const COLORS = {
    pending: "",
    scheduled: "",
    disapproved: theme.palette.error.main,
    approved: "#165D3A",
    rescheduled: "#B05E11",
  };

  return COLORS[id] || "";
};

export const getInspectionStateLabel = (id: InspectionState) => {
  const LABELS = {
    pending: "Not Scheduled",
    scheduled: "Scheduled",
    disapproved: "Disapproved",
    approved: "Approved",
    rescheduled: "Rescheduled",
  };

  return LABELS[id] || "";
};

const getStyles = (theme: Theme): { [key: string]: SxProps } => {
  return {
    container: {
      position: "relative",
      my: 1,
    },
    status: {
      display: "flex",
      alignItems: "center",
      py: 0.5,
      fontSize: "1.4rem",
    },
    divider: {
      borderColor: "#676b74",
      mx: 1,
    },
    textData: {
      fontSize: "1.2rem",
      m: 0,
      lineHeight: "1.38",
    },
    label: {
      textTransform: "uppercase",
    },
  };
};

export default function InspectionStatus(props: StatusProps) {
  const { data } = props;

  const theme = useTheme();
  const styles = getStyles(theme);

  if (!data) {
    return (
      <Box>
        <Box component="p" sx={styles.textData}>
          Inspection Status
        </Box>
        <Box sx={styles.status}>N/A</Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box component="p" sx={styles.textData}>
        Inspection Status
      </Box>
      <Box sx={styles.status}>
        <Box sx={styles.label} color={getColor(data.state, theme)}>
          {getInspectionStateLabel(data.state)}
        </Box>
        <Divider orientation="vertical" flexItem sx={styles.divider} />
        <Box color={getColor(data.state, theme)}>{data.date}</Box>
      </Box>
    </Box>
  );
}
