// reviews.json?page=1&limit=4&applicationId={{application_id}}&expand=true&divisionName=intake
import { getDivisionOrderById } from 'app-sections/common/applications/details/components/sections/status-info/components/divisions-info/helpers';
import { orderBy } from 'lodash-es';
import {
  ApiCollectionResponse,
  Params,
  Review,
  ReviewSectionApprovalCommentId,
  ReviewSectionState,
  ReviewSectionStateId,
} from 'types';

import { getCollectionResponse, request } from '../config';

const REVIEWS_URL = '/reviews';
const GET_REVIEWS_URL = '/reviews.json';

export function getReviews<T>(applicationId: string): Promise<T> {
  return request(
    {
      url: GET_REVIEWS_URL,
      method: 'GET',
      params: { applicationId, expand: true },
    },
    (data) => orderBy(data.models.results, [(i) => getDivisionOrderById(i.divisionName)])
  );
}

export function getReviewById(id: string): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${id}.json`,
      method: 'GET',
      params: { expand: true },
    },
    (data) => data.model
  );
}

export interface ChangeReviewerData {
  reviewerId: string;
  reviewId: string;
}

export function changeReviewers(data: ChangeReviewerData[]): Promise<PromiseSettledResult<Review>[]> {
  return Promise.allSettled(data.map(({ reviewId, reviewerId }) => changeReviewer(reviewId, reviewerId)));
}

export function changeReviewer(reviewId: string, reviewerId: string): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${reviewId}/change-reviewer.json`,
      method: 'PATCH',
      data: { review: { reviewerId } },
    },
    (data) => data.model
  );
}

export function addReviewDivision(divisionName: string, applicationId: string): Promise<Review> {
  return request(
    {
      url: GET_REVIEWS_URL,
      method: 'POST',
      data: { review: { applicationId, divisionName } },
    },
    (data) => data.model
  );
}

export function deleteReviewDivision(reviewId: string): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${reviewId}`,
      method: 'DELETE',
    },
    (data) => data.model
  );
}

export function updateReviewState(
  reviewId: string,
  sectionId: ReviewSectionStateId,
  state: ReviewSectionState,
  commentSectionId: ReviewSectionApprovalCommentId,
  approvalComment: string
): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${reviewId}.json`,
      method: 'PATCH',
      data: {
        review: { [sectionId]: state, [commentSectionId]: approvalComment },
      },
    },
    (data) => data.model
  );
}

export function acceptReview(reviewId: string, conditionalRestrictions: string): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${reviewId}/final-acceptance.json`,
      method: 'PATCH',
      data: { review: { conditionalRestrictions } },
    },
    (data) => data.model
  );
}

export function rejectReview(reviewId: string, rejectionReason: string): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${reviewId}/reject.json`,
      method: 'PATCH',
      data: { review: { rejectionReason } },
    },
    (data) => data.model
  );
}

export function getReviewsForDashboard(params: Params): Promise<ApiCollectionResponse<Review>> {
  return request(
    {
      url: GET_REVIEWS_URL,
      method: 'GET',
      params,
    },
    (data) => {
      return getCollectionResponse<Review>(data);
    }
  );
}

export function getReviewsPaginated<T>(data: Params, signal?: AbortSignal): Promise<ApiCollectionResponse<T>> {
  return request(
    {
      url: GET_REVIEWS_URL,
      method: 'GET',
      params: { ...data, expand: true },
      signal,
    },
    (data) => {
      return getCollectionResponse<T>(data);
    }
  );
}

export function MarkAsInspectionPassed(reviewId: string): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${reviewId}/change-inspections-state.json`,
      method: 'PATCH',
      data: {
        review: {
          inspectionsState: 'approved',
        },
      },
    },
    (data) => data.model
  );
}

export function MarkAsInspectionRequired(reviewId: string): Promise<Review> {
  return request(
    {
      url: `${REVIEWS_URL}/${reviewId}/change-inspections-state.json`,
      method: 'PATCH',
      data: {
        review: {
          inspectionsState: 'client_action_needed',
        },
      },
    },
    (data) => data.model
  );
}
