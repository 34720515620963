import { getInspectionStatus } from 'app-sections/admin/dashboard-new/components/column-data-types/helpers';
import { getInspectionStateLabel } from 'app-sections/admin/dashboard-new/components/column-item/components/InspectionStatus';
import { get } from 'lodash-es';
import { DivisionId, Review, ReviewState } from 'types';
import { StateInfoRow } from 'ui/components/state-info-card/StateInfoCard';
import { parseDate, UI_DATE_TIME_FORMAT_STRING } from 'ui/helpers/parseDate';

import { Box } from '@mui/material';

export const DIVISIONS: { name: string; id: DivisionId; order: number }[] = [
  {
    name: 'Intake',
    id: 'intake',
    order: 0,
  },
  {
    name: 'Zoning',
    id: 'zoning',
    order: 1,
  },
  {
    name: 'Green',
    id: 'green',
    order: 2,
  },
  {
    name: 'Structural/Fire',
    id: 'structural_fire',
    order: 3,
  },
  {
    name: 'Inspections',
    id: 'inspections',
    order: 4,
  },
  {
    name: 'DOEE',
    id: 'doee',
    order: 5,
  },
  {
    name: 'CofO Issuance',
    id: 'cfo_issuance',
    order: 6,
  },
];

const TAB_STATES_FIELDS = [
  'applicantValidationState',
  'existingCOOState',
  'propertySizeState',
  'permitsState',
  'inspectionsState',
  'finalAcceptanceState',
];

export function getDivisionsFromArray(divisions: DivisionId[]): string {
  return divisions.map((d) => getDivisionNameById(d)).join(', ');
}

export const getDivisionNameById = (id: string): string => {
  return DIVISIONS.find((item) => item.id === id)?.name || '';
};

export const getDivisionOrderById = (id: string): number => {
  return DIVISIONS.find((item) => item.id === id)?.order || 0;
};

export const getReviewLabel = (review: Review): string => {
  if (review.state === 'approved') {
    return `Approved on ${parseDate(review.completedAt, UI_DATE_TIME_FORMAT_STRING)}`;
  }

  if (review.state === 'application_denied_by_admin' || review.state === 'rejected') {
    return 'Application Denied';
  }

  if (review.state === 'application_deleted_by_client') {
    return 'Application Cancelled by Client';
  }

  if (review.state === 'client_action_needed' && review.divisionName === 'cfo_issuance') {
    return 'Waiting on client approval';
  }

  if (review.state === 'client_action_needed') {
    return 'Hold for correction';
  }

  const inProgress = TAB_STATES_FIELDS.filter((tab: string) => get(review, tab) && get(review, tab) !== 'pending');

  if (inProgress.length > 0) {
    return 'In progress';
  }

  return 'Review not started';
};

export const getReviewStatus = (review: Review): JSX.Element => {
  const label = getReviewLabel(review);
  if (review.state === 'approved') {
    return (
      <Box fontSize="2rem" fontWeight="bold" color="#166D12" textTransform="uppercase">
        {label}
      </Box>
    );
  }

  if (review.state === 'client_action_needed') {
    return (
      <Box fontSize="2rem" fontWeight="bold" color="#b05e11" textTransform="uppercase">
        {label}
      </Box>
    );
  }

  return (
    <Box fontSize="2rem" fontWeight="bold" textTransform="uppercase">
      {label}
    </Box>
  );
};

interface DivisionInfo {
  key: string;
  title: string;
  state: ReviewState;
  values: StateInfoRow[];
}

export const prepareDivisionsInfo = (reviews: Review[]): DivisionInfo[] => {
  return reviews.map((review) => {
    const values: StateInfoRow[] = [
      { label: 'Reviewer Name', value: review?.reviewerProfile?.fullName || 'Not assigned' },
      { label: 'Status', value: getReviewLabel(review) },
    ];

    if (review.divisionName === 'inspections') {
      const inspectionStatus = getInspectionStatus(
        review.inspectionEvents,
        review?.application?.inspectionsState || null
      );
      if (inspectionStatus !== null) {
        values.push({ label: 'Inspection Status', value: getInspectionStateLabel(inspectionStatus.state) });
        values.push({ label: 'Inspection Date', value: inspectionStatus.date });
      }
    }
    return {
      key: review.divisionName,
      title: getDivisionNameById(review.divisionName),
      state: review.state,
      values,
    };
  });
};
