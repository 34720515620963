import { getStateBadgeLabel } from 'app-sections/common/applications/details/components/details-header/components/application-state-badge/helpers';
import { APP_DETAILS_NAV_TABS, getLinkToDetails } from 'app-sections/user/applications/steps/config';
import { getApplicationTypeLabelById } from 'app-sections/user/applications/steps/step3/config';
import dayjs from 'dayjs';
import { orderBy } from 'lodash-es';
import { ApplicationReviewState, ApplicationState, InspectionEvent, Review } from 'types';
import { parseDate, UI_DATE_FORMAT_STRING } from 'ui/helpers/parseDate';

import { ColumnItemCard } from '../column-item/ColumnItem';
import { InspectionState, InspectionStatusData } from '../column-item/components/InspectionStatus';
import { ReviewSection } from '../column-item/components/Sections';
import { ActionNeededType } from '../column-item/components/StateBadgeSection';
import { WarningState } from '../column-item/components/Warnings';
import { ColumnId } from '../config';

const SECTIONS: ReviewSection[] = [
  { id: 'applicantValidationState', name: 'Applicant Validation', state: null },
  { id: 'existingCOOState', name: 'Existing C of O', state: null },
  { id: 'propertySizeState', name: 'Building description', state: null },
  { id: 'permitsState', name: 'Permits', state: null },
  { id: 'inspectionsState', name: 'Inspections', state: null },
  { id: 'state', name: 'Final Acceptance', state: null },
];

const INSPECTION_STATES_MAP: {
  [key in ApplicationReviewState]: InspectionState;
} = {
  pending: 'pending',
  passed: 'approved',
  failed: 'disapproved',
  scheduled: 'scheduled',
  rescheduled: 'rescheduled',
};

export function getInspectionStatus(
  events: InspectionEvent[],
  inspectionState: ApplicationReviewState | null
): InspectionStatusData | null {
  if (!events || events.length === 0 || !inspectionState) {
    return null;
  }

  const sortedEvents = orderBy(events, (e) => new Date(e.date).getTime(), ['desc']);

  const state = INSPECTION_STATES_MAP[inspectionState];
  if (state) {
    return {
      state: state,
      date: parseDate(sortedEvents[0].date, UI_DATE_FORMAT_STRING),
    };
  }
  return null;
}

function markExpiration(slaDeadlineAt: string, slaStoppedAt: string | null): boolean {
  if (!slaStoppedAt || dayjs(slaDeadlineAt).isBefore(slaStoppedAt)) {
    return true;
  }
  return false;
}

function getWarningState(
  slaDeadlineAt: string,
  slaStoppedAt: string | null,
  state: ApplicationState
): WarningState | null {
  if (state === 'swo') {
    return 'swo';
  }
  const today = new Date();
  const deadlineDate = new Date(slaDeadlineAt);
  if (deadlineDate < today && markExpiration(slaDeadlineAt, slaStoppedAt)) {
    return 'slaBreached';
  }

  const slaDiff = dayjs(slaDeadlineAt).diff(dayjs(), 'hours');
  if (slaDiff <= 24) {
    return 'closeDeadline';
  }

  return null;
}

function getSections(item: Review, id: ColumnId): any[] {
  if (id === 'pendingAssignment' || id === 'notStarted') {
    return [];
  }

  return SECTIONS.map((section) => {
    return {
      id: section.id,
      name: section.name,
      state: getSectionState(section, item),
    };
  });
}

function getSectionState(section: ReviewSection, item: Review) {
  const sectionState = item[section.id];
  if (section.id !== 'state' || sectionState === 'approved') {
    return sectionState;
  }

  return null;
}

function checkIfAnyReviewerActionNeeded(item: Review): boolean {
  return SECTIONS.some((section) => item[section.id] === 'reviewer_action_needed');
}

function getActionNeeded(item: Review, id: ColumnId): ActionNeededType {
  if (id !== 'updateNeeded') {
    return null;
  }
  if (checkIfAnyReviewerActionNeeded(item)) {
    return 'check_response';
  }

  if (item.issuanceState === 'reviewer_action_needed' && item.application.state === 'ready_for_issuance') {
    return 'publish_draft';
  }

  if (item.issuanceState === 'reviewer_action_needed' && item.application.state === 'certificate_draft_issued') {
    return 'answer_correction_request';
  }

  return null;
}

function getState(item: Review): string {
  // Check if Correction Request was reassigned to another reviewer (division)
  if (
    item.application.state === 'certificate_draft_issued' &&
    item.issuanceState === 'reviewer_action_needed' &&
    item.divisionName !== 'cfo_issuance'
  ) {
    return 'Team Task Transferred';
  }
  return getStateBadgeLabel('admin', item.application.state);
}

export function parseItems(items: Review[], id: ColumnId): ColumnItemCard[] {
  return items.map((item) => {
    return {
      id: item.applicationId,
      title: item.application.certificatePermitNumber,
      href: `/admin/${getLinkToDetails(item.applicationId, APP_DETAILS_NAV_TABS[0].id)}`,
      address: item.application.propertyAddressString,
      type: item.application.typeOfApplication ? getApplicationTypeLabelById(item.application.typeOfApplication) : '',
      deadline: parseDate(item.application.slaDeadlineAt, UI_DATE_FORMAT_STRING),
      state: getState(item),
      sections: getSections(item, id),
      warning: getWarningState(item.application.slaDeadlineAt, item.application.slaStoppedAt, item.application.state),
      inspectionStatus: getInspectionStatus(item.inspectionEvents, item.application.inspectionsState),
      actionNeeded: getActionNeeded(item, id),
    };
  });
}
