import { getApplicationById } from 'core/api/applications';
import { useApplicationsStore } from 'core/store/applications';
import { useAuthStore } from 'core/store/auth';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DataLoader from 'ui/components/data-loader/DataLoader';
import ErrorsList from 'ui/components/errors-list/ErrorsList';
import { isEnv } from 'ui/helpers/environments';

import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { applicationsResolverGuards } from './resolver-guards';

export interface ApplicationsResolverProps {
  children: any;
}

function shouldUseGuards(): boolean {
  // Turn off guards for localhost
  return !isEnv('local');
}

export default function ApplicationsResolver(props: ApplicationsResolverProps) {
  const { children } = props;
  const { currentUser } = useAuthStore();
  const { id } = useParams();
  const location = useLocation();

  const navigate = useNavigate();
  const { setActiveApplicationId, setLatestValidStep } = useApplicationsStore();

  const [redirect, setRedirect] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (redirect !== '') {
      navigate(redirect);
      setRedirect('');
      setIsValid(true);
    }
  }, [redirect, navigate]);

  useEffect(() => {
    setActiveApplicationId(id || '');
    setIsValid(false);
  }, [id]);

  const { isLoading, isError, error, isSuccess, data } = useQuery({
    queryKey: ['applications', id],
    queryFn: () => getApplicationById(id || ''),
    enabled: id !== '' && Boolean(currentUser),
    gcTime: 0,
  });

  useEffect(() => {
    if (isSuccess) {
      setLatestValidStep(data.lastSavedStep);
      let redirect = '';

      // SETUP GUARDS
      if (shouldUseGuards()) {
        redirect = applicationsResolverGuards(data, location, currentUser);
      }

      if (redirect !== '') {
        setRedirect(redirect);
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [isSuccess, location, data?.state]);

  useEffect(() => {
    if (isError) {
      // just to show error message
      setIsValid(true);
    }
  }, [isError]);

  if (isLoading || !isValid) {
    return (
      <Box mt={4}>
        <DataLoader />
      </Box>
    );
  }

  if (!isLoading && isError) {
    return (
      <Box p={3}>
        <ErrorsList title="Unexpected loading error" errors={error} />
      </Box>
    );
  }

  return <>{children}</>;
}
