import { UserRole } from 'types';

/**
 * Admin can join to the application and review it, can view all users and all applications
 * @param roles: UserRole[]
 * @returns
 */
export const isAdmin = (roles: UserRole[]): boolean => roles.includes('admin');

/**
 * Manager is Admin that can add and edit other admins
 * @param roles: UserRole[]
 * @returns
 */
export const isManager = (roles: UserRole[]): boolean => roles.includes('admin') && roles.includes('manager');

/**
 * Client is regular user
 * @param roles: UserRole[]
 * @returns
 */
export const isClient = (roles: UserRole[]): boolean => roles.includes('client');

// FLAGS

/**
 * Check if Manager has flag that allows login as other users on Production
 * @param roles: UserRole[]
 * @returns
 */
export const canAdminLoginAs = (roles: UserRole[]): boolean => isManager(roles) && roles.includes('can_login_as');
