import { isEnv } from 'ui/helpers/environments';

export const getErrorMessage = (error: Error): string => {
  if (!error.stack) {
    return error.message || error.toString();
  }
  return error.stack;
};

export const isRaygunEnabled = (): boolean => {
  // only for UAT and PROD
  return isEnv('uat') || isEnv('production');
};
